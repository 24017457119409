<template>
    <div>
        <div class="header">
            <span class="header_txt">直播列表</span>
            <button class="header_btn" @click="addLive">添加直播</button>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable">
                <template slot-scope="{row}" slot="status">
                    <span v-if="row.status == 0" style="color:rgb(144, 147, 153);">未开播</span>
                    <span v-if="row.status == 1" style="color:#19ad18;">直播中</span>
                </template>
                <template slot-scope="{row}" slot="live_view">
                    <span v-if="row.live_view">{{row.live_view}}</span>
                    <span v-else>0</span>
                </template>
                <template slot-scope="{row}" slot="page_view">
                    <span v-if="row.page_view">{{row.page_view}}</span>
                    <span v-else>0</span>
                </template>
                <template slot-scope="{row}" slot="book_view">
                    <span v-if="row.book_view">{{row.book_view}}</span>
                    <span v-else>0</span>
                </template>
                <template slot-scope="{row}" slot="sign_view">
                    <span v-if="row.sign_view">{{row.sign_view}}</span>
                    <span v-else>0</span>
                </template>
                <template slot-scope="{row}" slot="type">
                    <div class="liveModal_box">
                        <div class="liveModal_text" @click="editLive(row)">编辑</div>
                        <div class="liveModal_text" @click="bind(row)">绑定活动</div>
                        <div class="liveModal_text" @click="setUp(row)">虚拟设置</div>
                        <div class="liveModal_text" @click="address(row)">推流地址</div>
                        <div class="liveModal_text" @click="secretKey(row)">推流秘钥</div>
                        <div class="liveModal_text" @click="startStreaming">开始推流</div>
                    </div>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 添加直播弹框 -->
        <Modal v-model="addLiveModal" title="直播信息" width="50%">
            <div>
                <Form ref="addLiveListForm" :model="addLiveListForm" :rules="addLiveListFormRules" :label-width="120">
                    <FormItem label="直播名称" prop="name">
                        <Input type="text" v-model="addLiveListForm.name" style="width: 100%;"></Input>
                    </FormItem>
                    <FormItem label="直播喇叭" prop="notice">
                        <Input type="text" v-model="addLiveListForm.notice" style="width: 100%;"></Input>
                    </FormItem>
                    <FormItem label="直播开关">
                        <RadioGroup v-model="addLiveListForm.live_switch">
                            <Radio v-for="item in switchList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem label="直播时间" prop="start_time">
                        <DatePicker type="datetime" placeholder="选择日期时间" style="width: 200px" :value="addLiveListForm.start_time" @on-change='dateChange'></DatePicker>
                    </FormItem>
                    <!-- <FormItem label="推流方式">
                        <RadioGroup v-model="addLiveListForm.way"> 
                            <Radio v-for="item in wayList" :label="item.value" :key="item.value"><span>{{item.label}}</span></Radio>
                        </RadioGroup>
                    </FormItem> -->
                    <FormItem label="聊天文字颜色" prop="chat_text_color">
                        <ColorPicker v-model="addLiveListForm.chat_text_color" style="cursor: pointer;"/>
                    </FormItem>
                    <FormItem label="订单文字颜色" prop="order_text_color">
                        <ColorPicker v-model="addLiveListForm.order_text_color" style="cursor: pointer;"/>
                    </FormItem>
                    <FormItem label="问答文字颜色" prop="qa_text_color">
                        <ColorPicker v-model="addLiveListForm.qa_text_color" style="cursor: pointer;"/>
                    </FormItem>
                    <FormItem label="喇叭文字颜色" prop="notice_text_color">
                        <ColorPicker v-model="addLiveListForm.notice_text_color" style="cursor: pointer;"/>
                    </FormItem>
                    <FormItem label="直播封面" prop="cover_id">
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="coverSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div class="form_upload">
                                <img v-if="addLiveListForm.cover_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="addLiveListForm.cover.path" alt="">
                                <Icon v-else class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="直播背景图" prop="bg_id">
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="bgSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div class="form_upload">
                                <img v-if="addLiveListForm.bg_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="addLiveListForm.bg.path" alt="">
                                <Icon v-else class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label="直播视频" prop="video_id">
                        <h4>(视频最大128M)</h4>
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['mp4', 'avi', 'mov']"
                        :max-size="128 * 1024"
                        :on-format-error="handleFormatError"
                        :on-success="videoSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadVideo"
                        >
                            <div class="form_upload">
                                <video v-if="addLiveListForm.video_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="addLiveListForm.video.path"></video>
                                <Icon v-else class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="addLiveModalF">取消</Button>
                <Button type="primary" @click="addLiveModalT('addLiveListForm')">确定</Button>
            </div>
        </Modal>
         <!-- 绑定活动弹框 -->
         <Modal v-model="bindModal" title="绑定活动" width="80%">
            <div>
                <div class="bindModal_header">
                    <span>活动类型:</span>
                    <Select v-model="storePramsModal.type" style="width:200px;margin: 0 10px;" @on-change="activityType">
                        <Option v-for="item in liveTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                    <span>状态:</span>
                    <Select v-model="storePramsModal.status" style="width:200px;margin: 0 10px;" @on-change="state">
                        <Option v-for="item in liveStateList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div>
                <Table :columns="bindTable" :data="bindDataTable" :no-data-text="noDataText" border>
                    <template slot-scope="{row}" slot="live">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center;">
                            <img v-if="row.live" :src="row.live.path" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                    <template slot-scope="{row}" slot="type">
                        <span v-if="row.type">{{liveTypeList[row.type - 1].label}}</span>
                    </template>
                    <template slot-scope="{row}" slot="status">
                        <span v-if="row.status == '未开始'" style="color:#e6a23c">{{row.status}}</span>
                        <span v-if="row.status == '进行中'" style="color:#409eff">{{row.status}}</span>
                        <span v-if="row.status == '已结束'" style="color:rgb(144, 147, 153)">{{row.status}}</span>
                    </template>
                    <template  slot-scope="{row}" slot="types">
                        <!-- 参数 -->
                        <span v-if="chooseActiveId.indexOf(row.id) == -1" class="liveModal_text" @click="modaladd(row)">加入</span>
                        <span v-else class="liveModal_text" style="color:#f00" @click="modaldel(row)">删除</span>
                    </template>
                </Table>
                <div class="footer" style="margin: 20px 0;">
                    <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
                </div>
                <!-- 加入活动 -->
                <div class="bindModal_foot">
                    <div class="bindModal_box" v-for="(item,index) in bindModalBox" :key="index">
                        <div v-if="item.active.cover" style="width: 80px;height: 80px;position: relative;">
                            <img style="width: 80px;height: 80px;" :src="item.active.cover.path" alt="">
                            <img src="@/assets/delete.png" alt="" style="width: 16px;height: 16px;position: absolute;top: -10px;right: -5px;cursor: pointer;" @click.stop="goDeletion(item)">
                        </div>
                        <div v-else style="width: 80px;height: 80px;position: relative;">
                            <div style="width: 80px;height: 80px;background-color: #f5f7fa;color:#c0c4cc;line-height:80px;text-align:center;">加载失败</div>
                            <img src="@/assets/delete.png" alt="" style="width: 16px;height: 16px;position: absolute;top: -10px;right: -5px;cursor: pointer;" @click.stop="goDeletion(item)">
                        </div>
                        <span class="bindModal_span">{{item.active.name}}</span>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <Button @click="bindModalF">取消</Button>
                <Button type="primary" @click="bindModalT">绑定</Button>
            </div>
        </Modal>
        <!-- 虚拟设置弹框 -->
        <Modal v-model="setUpModal" :title="liveName" width="50%">
            <div>
                <Form ref="setUpListForm" :model="setUpListForm" :label-width="100">
                    <FormItem label="直播推流" required>
                        <InputNumber :max="10000" :min="0" v-model="setUpListForm.live_view" style="width: 100%;"></InputNumber>
                    </FormItem>
                    <FormItem label="浏览推流" required>
                        <InputNumber :max="10000" :min="0" v-model="setUpListForm.page_view" style="width: 100%;"></InputNumber>
                    </FormItem>
                    <FormItem label="预约推流" required>
                        <InputNumber :max="10000" :min="0" v-model="setUpListForm.book_view" style="width: 100%;"></InputNumber>
                    </FormItem>
                    <FormItem label="报名推流" required>
                        <InputNumber :max="10000" :min="0" v-model="setUpListForm.sign_view" style="width: 100%;"></InputNumber>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="setUpModalF">取消</Button>
                <Button type="primary" @click="setUpModalT('setUpListForm')">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import config from "@/config/index";
import { getCookies } from "@/utils/helper";
import {liveList, activeList, liveCreate, liveActive, joinActive, deleteActive, modifyVirtually, livePush} from '@/api/index'
    export default {
        data() {
            return {
                uploadImg: config.apiUrl + "/admin/other/uploads",
                uploadVideo: config.apiUrl + "/admin/other/uploads_video",
                headers: {"meiye-admin-api": getCookies("meiye-admin-api")},
                switchList:[
                    {label:'不显示',value: 0},
                    {label:'关',value: 1},
                    {label:'开',value: 2}
                ],
                // wayList:[
                //     {label:'直播推流',value:'0'},
                //     {label:'obs',value:'1'}
                // ],
                //table
                liveTable: [
                    {title: '频道号',key: 'id',align:'center'},
                    {title: '直播名称',key: 'name',align:'center'},
                    {title: '开播时间',key: 'start_time',align:'center'},
                    {title: '直播状态',slot: 'status',align:'center'},
                    {title: '直播推流',slot: 'live_view',align:'center'}, 
                    {title: '浏览推流',slot: 'page_view',align:'center'},
                    {title: '预约推流',slot: 'book_view',align:'center'},
                    {title: '报名推流',slot: 'sign_view',align:'center'},
                    {title: '操作',slot: 'type',align:'center',width:'200'}
                ],
                dataTable: [],
                //page
                total: 1,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                addLiveModal:false,
                addLiveListForm:{
                    name:'',
                    notice:'',
                    live_switch:'',
                    start_time:'',
                    // way:'',
                    chat_text_color:'',
                    order_text_color:'',
                    qa_text_color:'',
                    notice_text_color:'',
                    cover_id:'',
                    bg_id:'',
                    video_id:'',
                },
                // 添加直播弹框-表单验证
                addLiveListFormRules: {
                    name: [
                        { required: true, message: '请输入直播名称', trigger: 'blur' }
                    ],
                    horn: [
                        { required: true, message: '请输入喇叭内容', trigger: 'blur' }
                    ],
                    time: [
                        { required: true, message: '请选择直播时间', trigger: 'blur' }
                    ],
                    textColor: [
                        { required: true, message: '', trigger: 'blur' }
                    ],
                    orderColor: [
                        { required: true, message: '', trigger: 'blur' }
                    ],
                    qaColor: [
                        { required: true, message: '', trigger: 'blur' }
                    ],
                    hornColor: [
                        { required: true, message: '', trigger: 'blur' }
                    ],
                    cover: [
                        { required: true, message: '请上传直播封面图', trigger: 'blur' }
                    ],
                    backdrop: [
                        { required: true, message: '请上传直播背景图', trigger: 'blur' }
                    ],
                },
                bindModal:false,
                liveTypeList: [
                    {value: '1',label: '限时特价'},
                    {value: '2',label: '秒杀活动'},
                    {value: '3',label: '种草活动'},
                    {value: '4',label: '活动预约'},
                    {value: '5',label: '拼团活动'},
                    {value: '6',label: '定金预售'},
                ],
                // liveType:'0',
                liveStateList: [
                    {value: '0',label: '未开始'},
                    {value: '1',label: '进行中'},
                    {value: '2',label: '已结束'}
                ],
                // liveState:'0',
                totalModal:0,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    type:'1',
                    status:'0'
                },
                noDataText:'暂无数据',
                bindTable: [
                    {title: '活动名称',key: 'name',align:'center'},
                    {title: '直播间显示图',slot: 'live',align:'center'},
                    {title: '活动类型',slot: 'type',align:'center'},
                    {title: '状态',slot: 'status',align:'center'},
                    {title: '开始时间',key: 'start_time',align:'center'},
                    {title: '结束时间',key: 'end_time',align:'center'},
                    {title: '操作',slot: 'types',align:'center'}
                ],
                bindDataTable: [],
                bindModalBox:[],
                setUpModal:false,
                setUpListForm:{
                    live_view: 0,
                    page_view: 0,
                    book_view: 0,
                    sign_view: 0,
                },
                liveActives:'',
                liveId:'',
                chooseActiveId: [],
                liveName:''
            }
        },
        mounted(){
            this.liveList()
        },
        methods: {
            dateChange(date){
                this.addLiveListForm.start_time = date
            },
            // 加入
            modaladd(item){
                console.log('加入',item)
                let data = {
                    active_id: item.id,
                    live_id: this.liveId
                }
                this.chooseActiveId.push(item.id)
                joinActive(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg);
                })
            },
            // 删除
            modaldel(item){
                console.log('删除',item)
                let data = {
                    active_id: item.id,
                    live_id: this.liveId
                }
                var index = this.chooseActiveId.indexOf(item.id)
                if (index > -1) {
                    this.chooseActiveId.splice(index, 1)
                }
                deleteActive(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg);
                })
            },
            // 快捷删除
            goDeletion(item){
                console.log('删除',item)
                let data = {
                    active_id: item.active_id,
                    live_id: this.liveId
                }
                var index = this.chooseActiveId.indexOf(item.id)
                if (index > -1) {
                    this.chooseActiveId.splice(index, 1)
                }
                deleteActive(data).then(res => {
                    this.liveActive()
                    this.$Message.success(res.msg);
                })
            },
            // 直播间列表
            liveList(){
                liveList(this.storePrams).then(res => {
                    console.log('直播间列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            addLive(){
                this.addLiveListForm.name = '',
                this.addLiveListForm.notice= '',
                this.addLiveListForm.live_switch= '',
                this.addLiveListForm.start_time= '',
                this.addLiveListForm.chat_text_color= '',
                this.addLiveListForm.order_text_color= '',
                this.addLiveListForm.qa_text_color= '',
                this.addLiveListForm.notice_text_color= '',
                this.addLiveListForm.cover_id= '',
                this.addLiveListForm.bg_id= '',
                this.addLiveListForm.video_id= '',
                this.addLiveListForm.cover= {},
                this.addLiveListForm.bg= {},
                this.addLiveListForm.video= {},
                this.addLiveModal = true   
                this.liveActives = 0  
                this.addLiveListForm.live_switch = 0    
                this.addLiveListForm.id = ''   
            },
            // 编辑
            editLive(item){
                console.log('item',item)
                this.addLiveListForm = item
                this.addLiveModal = true  
                this.liveActives = 1
                if(!this.addLiveListForm.cover_id){
                    this.addLiveListForm.cover= {}
                }
                if(!this.addLiveListForm.bg_id){
                    this.addLiveListForm.bg= {}
                }
                if(!this.addLiveListForm.video_id){
                    this.addLiveListForm.video= {}
                }
                this.addLiveListForm.id = item.id  
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            addLiveModalF(){
                this.addLiveModal = false         
            },
            addLiveModalT(name){
                if(!this.liveActives){
                    let data = {
                        name: this.addLiveListForm.name,
                        notice: this.addLiveListForm.notice,
                        live_switch: this.addLiveListForm.live_switch,
                        start_time: this.addLiveListForm.start_time,
                        video_id: this.addLiveListForm.video_id,  
                        chat_text_color: this.addLiveListForm.chat_text_color, 
                        order_text_color: this.addLiveListForm.order_text_color,
                        qa_text_color: this.addLiveListForm.qa_text_color,  
                        notice_text_color: this.addLiveListForm.notice_text_color,
                        cover_id: this.addLiveListForm.cover_id,   
                        bg_id: this.addLiveListForm.bg_id,  
                    }
                    liveCreate(data).then(res => {
                        this.$Message.success(res.msg);
                        this.addLiveModal = false    
                        this.liveList()
                    })
                }else{
                    liveCreate(this.addLiveListForm).then(res => {
                        this.$Message.success(res.msg);
                        this.addLiveModal = false    
                        this.liveList()
                    })
                }
            },
            // 绑定活动
            bind(item){
                console.log('item',item)
                this.liveId = item.id
                this.bindModal = true
                this.liveActive()
                this.activeList()
            },
            // 已绑定的活动
            liveActive(){
                liveActive({live_id:this.liveId}).then(res => {
                    console.log('已绑定的活动',res.data)
                    this.bindModalBox = res.data
                    this.chooseActiveId = []
                    res.data.forEach(item => 
                        this.chooseActiveId.push(item.active_id)
                    )
                    console.log('333', this.chooseActiveId)
                })
            },
            activityType(e){
                this.storePramsModal.type = e
                this.activeList()
            },
            // 状态
            state(e){
                this.storePramsModal.status = e
                this.activeList()
            },
            // 活动列表
            activeList(){
                activeList(this.storePramsModal).then(res => {
                    console.log('活动列表',res.data.data)
                    this.totalModal = res.data.total
                    this.bindDataTable = res.data.data
                })
            },
            bindModalF(){
                this.bindModal = false
            },
            bindModalT(){
                this.bindModal = false
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            setUp(item){
                this.liveName = item.name
                this.setUpModal = true
                this.liveId = item.id
                if(item.page_view){
                    this.setUpListForm.page_view = item.page_view
                }else{
                    this.setUpListForm.page_view = 0
                }
                if(item.live_view){
                    this.setUpListForm.live_view = item.live_view
                }else{
                    this.setUpListForm.live_view = 0
                }
                if(item.book_view){
                    this.setUpListForm.book_view = item.book_view
                }else{
                    this.setUpListForm.book_view = 0
                }
                if(item.sign_view){
                    this.setUpListForm.sign_view = item.sign_view
                }else{
                    this.setUpListForm.sign_view = 0
                }
            },
            setUpModalF(){
                this.setUpModal = false
            },
            setUpModalT(name){
                let data = {
                    id: this.liveId,
                    page_view: this.setUpListForm.page_view,
                    live_view: this.setUpListForm.live_view,
                    book_view: this.setUpListForm.book_view,
                    sign_view: this.setUpListForm.sign_view
                }
                modifyVirtually(data).then(res => {
                    this.setUpModal = false
                    this.liveList()
                })
            },
            //推流地址
            address(i){
                // 直播间推流地址
                livePush({id: i.id}).then(res => {
                    console.log('直播间推流地址', res.data.push_url)
                    this.$copyText(res.data.push_url).then(
                        (e) => {
                            this.$Message.success({
                                background: true,
                                content: '复制成功',
                                closable: true
                            });
                        },
                        (err) => {
                        alert("复制失败");
                            console.log(err);
                        }
                    );
                })
            },
            //推流秘钥
            secretKey(i){
                // 直播间推流秘钥
                livePush({id: i.id}).then(res => {
                    console.log('直播间推流秘钥', res.data.push_key)
                    this.$copyText(res.data.push_key).then(
                        (e) => {
                            this.$Message.success({
                                background: true,
                                content: '复制成功',
                                closable: true
                            });
                        },
                        (err) => {
                        alert("复制失败");
                            console.log(err);
                        }
                    );
                })
            },
            //开始推流
            startStreaming(){
                this.$Message.success({
                    background: true,
                    content: '推流开始',
                    closable: true
                });
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress(event, file, fileList) {
                if(event.percent == 100){
                    const msg = this.$Message.loading({
                        content: "上传中...",
                        duration: 0,
                    });
                    setTimeout(msg, 3000);
                }
            },
            // 直播封面上传
            coverSuccess(e){
                console.log("图片上传", e);
                this.addLiveListForm.cover.path = e.data.path
                this.addLiveListForm.cover_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 直播背景图上传
            bgSuccess(e){
                console.log("图片上传", e);
                this.addLiveListForm.bg.path = e.data.path
                this.addLiveListForm.bg_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 直播视频上传
            videoSuccess(e) {
                console.log("视频上传", e);
                this.addLiveListForm.video.path = e.data.path
                this.addLiveListForm.video_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.content{
    margin: 5px 0;
}
::v-deep .ivu-table-tbody{
    height: 200px;
}
.footer{
    margin-top: 20px;
    text-align: center;
}
.form_upload{
    width: 180px;
    height: 180px;
    border: 1px dashed #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.form_upload:hover{
    border: 1px dashed #409eff;
}
.form_icon{
    font-size: 30px;
    color: #808080;
}
.liveModal_box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.liveModal_text{
    margin: 10px 0;
    color: #409eff;
    cursor: pointer;
}
.bindModal_header{
    margin: 10px 0;
}
.bindModal_foot{
    margin: 10px 0 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.bindModal_box{
    margin: 5px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.bindModal_span{
    width: 70px;
    overflow:hidden;//超出部分隐藏
    white-space:nowrap;//禁止换行
    text-overflow:ellipsis//省略号
}
</style>